export interface configDevType {
  api_timeout: number;
  app_version: string;
  mode: string;
  api_baseUrl: string;
  api_baseUrl_Picture: string;
  signalR_hub_url: string;
}
export const configDev: configDevType = {
  api_timeout: 5000,
  app_version: '1.1.0',
  mode: 'dev',
  api_baseUrl: 'https://backendbackoffice.frenchinnov.fr',
  //api_baseUrl: 'http://192.168.1.35:7029',
  api_baseUrl_Picture: 'https://backendbackoffice.frenchinnov.fr/uploads/',
  signalR_hub_url: '/chatHub',
};
