import React from 'react';
import CardContainer from '../../CardContainer';
import GridItems from '../../GridList/GridList';
import InputField from '../../../components/Input/Input';
import SelectInput from '../../../components/Input/SelectInput';
import { languageData } from '../../../i18n';
import { formatDateToDayMonthYear } from '../../../utils/DateFormatter';
import flagsAndNamesSeparator from '../../../utils/FlagsAndNamesSeparator';
import { getErrorEditCompany } from '../../../utils/GetErrors';
import { getErrorMessageEditCompany } from '../../../utils/GetErrorMessages';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import { extractDigits } from '../../../utils/PhoneNumbersUtils';
import CountriesFlagsAndCodes, {
  CountriesFlagsAndCodesItem,
} from '../../../data/CountriesFlagsAndCode';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEditFormDataFiche,
  setEditFormDirty,
} from '../../../store/actions/actions';
import SelectInput2 from '../../../components/Input/CountrySelect/SelectInput2';
interface propsType {
  id: number;
  isClaimsValid?: boolean;
  fichModifier?: boolean;
}
export default function FicheTab(props: propsType) {
  const dispatch = useDispatch();
  const [phoneCode] = React.useState<string>();
  const companySheet = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.companySheet,
  );

  const editCompanyFormData = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.editCompanyFormData,
  );

  const validationRequested = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.validationRequestedEditFicheTab,
  );
  const { countriesCodes, countriePhoneCodes, flags } =
    flagsAndNamesSeparator();
  const formattedDate = formatDateToDayMonthYear(
    companySheet?.construction_date,
  );
  React.useEffect(() => {
    dispatch(
      setEditFormDataFiche({
        ...editCompanyFormData,
        id: props.id,
        email: companySheet?.email,
        fixed_line_telephone_number: companySheet?.fixed_line_telephone_number,
        mobile_telephone_number: companySheet?.mobile_telephone_number,
        share_capital: companySheet?.share_capital,
        address: companySheet?.address,
        postal_code: companySheet.postal_code,
        country: companySheet?.country,
        city: companySheet?.city,
        sireN_code: companySheet?.sireN_code,
        vaT_Intracommunity: companySheet?.vaT_Intracommunity,
      }),
    );
  }, [companySheet.id]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch(setEditFormDirty(true));
    const data =
      name === 'fixed_line_telephone_number' ||
      name === 'mobile_telephone_number'
        ? extractDigits(value)
        : value;

    dispatch(
      setEditFormDataFiche({
        ...editCompanyFormData,
        [name]: data,
      }),
    );
  };

  const handleSelectPhoneCode = (
    selectedValue: string,
    type: 'fixed_line_telephone_number' | 'mobile_telephone_number',
  ) => {
    const selectedItem = CountriesFlagsAndCodes?.find(
      (item: CountriesFlagsAndCodesItem) => item.code === selectedValue,
    );
    dispatch(
      setEditFormDataFiche({
        ...editCompanyFormData,
        [type]: selectedItem?.phoneCode
          ? selectedItem.phoneCode?.toString()
          : '',
      }),
    );
  };
  return (
    <div className="TabContent">
      <div className="FicheTab  fl_col gp16">
        <div className="Section-data fl_col gp16">
          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.generalInformation}
          >
            <GridItems grid={2}>
              <InputField
                isRequired={true}
                type="text"
                name="company_name"
                value={companySheet?.company_name}
                label={languageData.global.Form.companyNameLabel}
                style="form"
                isDisabled={true}
                infoTitle="Info"
                info="Informations générales"
              />

              <InputField
                isRequired={true}
                type="text"
                name="commercial_Name"
                value={companySheet?.commercial_Name}
                label={languageData.global.Form.commercialNameLabel}
                // placeholder="Raison social"
                onChange={() => alert('x')}
                style="form"
                isDisabled={true}
              />

              <InputField
                isRequired={true}
                type="date"
                name="Date de création"
                label={languageData.global.Form.constructionDateLabel}
                value={formattedDate}
                style="inpdate"
                isDisabled={true}
              />

              <SelectInput
                isDisabled={true}
                label={languageData.global.Form.legalFormLabel}
                placeholder={companySheet?.legal_form?.name}
                target="dropdown"
                options={[]}
                errorMsg="One option at least is required"
                isRequired
              />

              <InputField
                isRequired={true}
                name="share_capital"
                type="number"
                value={editCompanyFormData.share_capital}
                onChange={handleChange}
                label={languageData.global.Form.shareCapitalLabel}
                style="form"
                infoTitle="Info"
                info="Informations générales"
                isDisabled={false}
              />

              <InputField
                isRequired={true}
                type="email"
                name="email"
                value={editCompanyFormData?.email}
                placeholder={companySheet?.email}
                label={languageData.global.Form.emailLabel}
                onChange={handleChange}
                style="form"
                isDisabled={!(props.isClaimsValid && props.fichModifier)}
                haserror={
                  validationRequested
                    ? getErrorEditCompany({
                        fieldName: 'email',
                        isRequired: true,
                        fieldValue: editCompanyFormData?.email,
                      })
                    : false
                }
                errorMsg={
                  validationRequested
                    ? getErrorMessageEditCompany({
                        fieldName: 'email',
                        fieldValue: editCompanyFormData?.email,
                      })
                    : ''
                }
              />

              <SelectInput2
                name="fixed_line_telephone_number"
                label={languageData.global.Form.telephoneLabel}
                placeholder={companySheet?.fixed_line_telephone_number}
                value={editCompanyFormData.fixed_line_telephone_number}
                target="reflags"
                icons={flags}
                reference={countriePhoneCodes}
                options={countriesCodes}
                isRequired={false}
                isDisabled={
                  props.isClaimsValid && props.fichModifier ? false : true
                }
                onChange={handleChange}
              />

              <SelectInput2
                label={languageData.global.Form.mobileLabel}
                name="mobile_telephone_number"
                placeholder={companySheet?.mobile_telephone_number}
                value={editCompanyFormData.mobile_telephone_number}
                target="reflags"
                isRequired
                icons={flags}
                reference={countriePhoneCodes}
                options={countriesCodes}
                onChange={handleChange}
                isDisabled={
                  props.isClaimsValid && props.fichModifier ? false : true
                }
                haserror={
                  validationRequested
                    ? getErrorEditCompany({
                        fieldName: 'mobile_telephone_number',
                        isRequired: true,
                        fieldValue:
                          editCompanyFormData?.mobile_telephone_number,
                      })
                    : false
                }
                errorMsg={
                  validationRequested
                    ? getErrorMessageEditCompany({
                        fieldName: 'mobile_telephone_number',

                        fieldValue:
                          editCompanyFormData?.mobile_telephone_number,
                      })
                    : ''
                }
              />
            </GridItems>
          </CardContainer>

          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.Form.addressLabel}
          >
            <GridItems grid={2}>
              <InputField
                isRequired={true}
                type="text"
                name="address"
                value={editCompanyFormData.address}
                label={languageData.global.Form.addressLabel}
                style="form"
                onChange={handleChange}
                isDisabled={false}
              />
              <InputField
                label={languageData.global.Form.countryLabel}
                value={editCompanyFormData.country}
                onChange={handleChange}
                name="country"
                placeholder={companySheet?.country}
                style="form"
                isRequired
                isDisabled={false}
              />
              <InputField
                label={languageData.global.Form.cityLabel}
                value={editCompanyFormData.city}
                name="city"
                placeholder={companySheet?.city}
                onChange={handleChange}
                style="form"
                isRequired
                isDisabled={false}
              />

              <InputField
                label={languageData.global.Form.postalCodeLabel}
                value={editCompanyFormData.postal_code}
                name="postal_code"
                isRequired={true}
                type="number"
                onChange={handleChange}
                isDisabled={false}
              />
            </GridItems>
          </CardContainer>

          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.fiscal_info}
          >
            <GridItems grid={3}>
              <InputField
                isRequired={true}
                value={editCompanyFormData.sireN_code}
                onChange={handleChange}
                type="number"
                name="sireN_code"
                label={languageData.global.siren}
                style="form"
                isDisabled={false}
              />
              <InputField
                isRequired={true}
                type="text"
                name="vaT_Intracommunity"
                value={editCompanyFormData.vaT_Intracommunity}
                label={languageData.global.vat_intra}
                onChange={handleChange}
                style="form"
                isDisabled={false}
              />
              <InputField
                isRequired={true}
                type="text"
                name="Numéro NAF"
                value={
                  companySheet?.naF_codes?.reference +
                  companySheet?.naF_codes?.designation
                }
                label={languageData.global.naf_number}
                style="form"
                isDisabled={true}
              />
            </GridItems>
          </CardContainer>
        </div>
      </div>
    </div>
  );
}
