import React, { ChangeEvent, useState } from 'react';
import EditGreyIcon from '../../assets/icons/EditGreyIcon.svg';
import ErrorIconRed from '../../assets/icons/ErrorIconRed.svg';
import ClearInputGrey from '../../assets/icons/ClearInputGrey.svg';
import SubtractInputPrimary from '../../assets/icons/SubtractInputPrimary.svg';
import {
  onKeyUpWithoutSymbol,
} from '../../utils/catalogueHelper/inputs';

/**
 * FieldName component description.
 *
 * @FieldName
 *
 */

interface FieldNameProps {
  label?: string;
  type?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  showEdit?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  defaultValue?: string;
  max?: number;
  errorMsg?: string;
  showEditTools?: boolean;
  onEdit?: () => void;
  disabelEditModeProps?: boolean;
}
const FieldName: React.FC<FieldNameProps> = ({
  label,
  type,
  placeholder,
  name,
  id,
  showEdit,
  onChange,
  onClick,
  defaultValue,
  max,
  errorMsg,
  showEditTools,
  onEdit,
  disabelEditModeProps,
}) => {
  const [disabelEditMode, setdisabelEditMode] = useState(
    disabelEditModeProps ?? false,
  );
  const HandelEditMode = () => {
    if (!disabelEditMode && onEdit) {
      onEdit();
    }
    setdisabelEditMode((prevState) => !prevState);
  };
  const ClearInput = () => {
    alert('Clear inp');
  };
  const ValidateInp = () => {
    alert('Validate inp');
  };

  return (
    <div
      className={`FieldName w-full fl_col gp5 relative ${disabelEditMode ? 'EditMode' : ''}`}
    >
      {label && (
        <label className="DarkBlueGray-txt p12 w-500 pd16-r-l" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="fl_row">
        {disabelEditMode ? (
          <div className="field-wrapper fl_row ai_c jc_s">
            <label className="black-txt-txt p16 w-500 pd16-r-l" htmlFor={id}>
              {defaultValue}
            </label>
            {showEdit && (
              <div className="tools">
                <img
                  className="c-p fl_row"
                  src={EditGreyIcon}
                  alt=""
                  width={20}
                  onClick={HandelEditMode}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <input
              onKeyUp={onKeyUpWithoutSymbol}
              autoComplete="off"
              defaultValue={defaultValue}
              className="EerieBlack p16 w-300 w-full pd16-r-l"
              maxLength={max ?? 25}
              type="text"
              placeholder={placeholder}
              name="name"
              id="id"
              onChange={onChange}
              onClick={onClick}
            />
            {showEditTools && (
              <div className="tools fl_row gp10">
                <img
                  className="c-p"
                  src={ClearInputGrey}
                  alt=""
                  width={20}
                  onClick={ClearInput}
                />
                <img
                  className="c-p"
                  src={SubtractInputPrimary}
                  alt=""
                  width={20}
                  onClick={HandelEditMode}
                />
              </div>
            )}
          </>
        )}
      </div>
      {errorMsg && (
        <div className="fl_row gp5 ai_c errorMsg">
          <img
            src={ErrorIconRed}
            alt=""
            style={{
              width: 15,
              height: 15,
            }}
          />
          <p className=" p12 VenetianRed-text">{errorMsg}</p>
        </div>
      )}
    </div>
  );
};

export default FieldName;
