import React, { ChangeEvent, useRef } from 'react';
import MinusArrowUpIconGrey from '../../assets/icons/MinusArrowUpIconGrey.svg';
import PlusArrowUpIconGrey from '../../assets/icons/PlusArrowUpIconGrey.svg';
import { languageData } from '../../i18n';
import {
  handleKeyDownNumber,
  onInputNumber,
  onInputNumberStep2,
  onKeyDownWithoutE,
  onKeyDownWithoutZero,
  onKeyUpRemoveZero,
  removeDecimal,
} from '../../utils/catalogueHelper/inputs';
import CurrencyInput from './CurrencyInput';
/**
 * NumberField component description.
 *
 * @NumberField
 *
 */

interface NumberFieldProps {
  label: string;
  prefix?: string;
  defaultValue?: number;
  name?: string;
  id?: string;
  width?: number;
  onClickPlus?: () => void;
  onClickMinus?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  hideControl?: boolean;
  max?: number;
  min?: number;
  step?: string;
  error?: boolean;
  error2?: boolean;
  type?: string;
}

const NumberField: React.FC<NumberFieldProps> = ({
  label,
  prefix,
  defaultValue,
  name,
  id,
  width,
  onClickPlus,
  onClickMinus,
  onChange,
  hideControl,
  min,
  max,
  step,
  error,
  error2,
  type,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickPlus = () => {
    if (inputRef.current) {
      inputRef.current.stepUp();
      onChange &&
        onChange({
          target: { value: inputRef.current.value },
        } as ChangeEvent<HTMLInputElement>);
    }
  };

  const handleClickMinus = () => {
    if (inputRef.current) {
      if (parseFloat(inputRef.current.value) ?? 0 > 0) {
        inputRef.current.stepDown();
        onChange &&
          onChange({
            target: { value: inputRef.current.value ?? 0 },
          } as ChangeEvent<HTMLInputElement>);
      }
    }
  };
  return (
    <div className="fl_col relative items-wrapper">
      <div style={{ backgroundColor: 'white' }} className="NumberField">
        <div className="field-wrapper  fl_col gp10 pd5-r-l">
          <label className="p14 w-600 DarkBlueGray-txt" htmlFor={id}>
            {label}
          </label>
          <div className="field fl_row ai_c jc_s pd12-r-l">
            {type == 'price' ? (
              <CurrencyInput
                defaultValue={defaultValue ?? ''}
                onChange={onChange}
              ></CurrencyInput>
            ) : (
              <input
                autoComplete="off"
                min={min ?? 0}
                maxLength={10}
                pattern="\d*"
                onKeyUp={step == '3' ? onKeyUpRemoveZero : undefined}
                onKeyDown={
                  step == '1'
                    ? handleKeyDownNumber
                    : step == '2'
                      ? onKeyDownWithoutE
                      : step == '3'
                        ? onKeyDownWithoutZero
                        : undefined
                }
                onInput={
                  step == '1'
                    ? onInputNumber
                    : step == '2'
                      ? onInputNumberStep2
                      : undefined
                }
                step={type == 'price' ? '0.01' : 1}
                ref={inputRef}
                style={{ width }}
                onChange={
                  step == '3' ? removeDecimal(onChange as any) : onChange
                }
                placeholder="0.00"
                type="number"
                name={name}
                id={id}
                defaultValue={defaultValue}
              />
            )}

            <div className="fl_row ai_c gp4 ">
              <div className="control-field fl_col gp2">
                <img
                  src={PlusArrowUpIconGrey}
                  alt=""
                  onClick={handleClickPlus}
                />
                <img
                  src={MinusArrowUpIconGrey}
                  alt=""
                  onClick={handleClickMinus}
                />
              </div>
              <p className="p14 prefix w-700 DarkBlueGray-txt">{prefix}</p>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <p className="p12 pd5 prefix w-500 ErrorField-txt">
          {error2
            ? languageData.fr.error.errorNrMx
            : max
              ? languageData.fr.error.min
              : languageData.fr.error.max}
        </p>
      )}
    </div>
  );
};

export default NumberField;
