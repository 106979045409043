import React from 'react';
import MarketsIconGrey from '../../assets/icons/MarketsIconGrey.svg';
import RemoveProductIcon from '../../assets/icons/RemoveProductIcon.svg';
import MenuPlaceHolder from '../../assets/images/MenuPlaceHolder.png';
import ButtonViewCartGrey from '../../assets/icons/ButtonViewCartGrey.svg';
/**
 * CartItem component description.
 *
 * @CartItem
 *
 */

interface CartItemProps {
  name?: string;
  img?: string;
  OnClickMarkets?: () => void;
  OnClickSettings?: () => void;
  OnClickRemove?: () => void;
  onClickView?: () => void;
  disabled?: boolean;
  onSelect?: () => void;
  selected?: boolean;
  hideAllOption?: boolean;
  className?: string;
  editMode?:boolean
}

const CartItem: React.FC<CartItemProps> = ({
  name,
  img,
  OnClickMarkets,
  OnClickSettings,
  onClickView,
  OnClickRemove,
  disabled,
  onSelect,
  selected,
  hideAllOption,
  className,
  editMode
}) => {
  return (
    <div
      className={`CartItem ${selected ? 'selected' : ''} ${className ?? ''}`}
      draggable="false"
    >
      <div
        className={`item-wrapper fl_col ai_c ${disabled ? 'disabled-element' : ''}`}
      >
        {OnClickRemove &&editMode&& (
          <img
            src={RemoveProductIcon}
            alt=""
            className="remove-item c-p"
            onClick={OnClickRemove}
          />
        )}
        <img
          width={150}
          src={img || MenuPlaceHolder}
          alt=""
          className="item-img"
          onClick={onSelect}
        />
        <div className="item-footer">
          <div className="footer-wrapper">
            <p className="p14 Independence-txt w-700 txt_center">{name}</p>
            {!hideAllOption && (
              <div className="fl_row jc_s gp20">
                {onSelect && (
                  <img
                    className="c-p"
                    src={ButtonViewCartGrey}
                    alt=""
                    onClick={onSelect}
                  />
                )}
                {OnClickMarkets && !hideAllOption && (
                  <img
                    className="c-p"
                    src={MarketsIconGrey}
                    alt=""
                    onClick={OnClickMarkets}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
