import React, { useState } from 'react';
import CardContainer from '../../CardContainer';
import ButtonElement from './../../../components/button/button';
import GridItems from '../../GridList/GridList';
import InputField from '../../../components/Input/Input';
import SelectInput from '../../../components/Input/SelectInput';
// import FrFlagCircle from '../../../assets/icons/FrFlagCircle.svg';
import { languageData } from '../../../i18n';

interface NaFCodes {
  id: number;
  reference: string;
  designation: string;
}
interface Status {
  id: number;
  isActive: boolean;
  name: string;
}
interface EtablissementSheet {
  address: string;
  city: string;
  commercial_Name: string;
  country: string;
  email: string;
  fixed_line_telephone_number: string;
  id: number;
  logo: any;
  mobile_telephone_number: string;
  naF_codes: NaFCodes;
  postal_code: string;
  share_capital: string;
  sireT_Code: string;
  vaT_Intracommunity: string;
}

interface EtablissementInfos {
  address: string;
  city: string;
  commercial_Name: string;
  country: string;
  fixed_line_telephone_number: string;
  logo: any;
  mobile_telephone_number: string;
  postal_code: string;
  sireT_Code: string;
  status: Status;
}

interface FicheTabEtablissementsProps {
  etablissementSheet: EtablissementSheet;
  etablissementInfos: EtablissementInfos;
  setFormDateState: any;
  formDateState: any;
  claimEtablissementModification: boolean;
}

export default function FicheTabEtablissements(
  props: FicheTabEtablissementsProps,
) {
  const handleChange =
    (field: keyof serviceEtablissmentUpdate.IformDateState) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      props.setFormDateState(
        (prevState: Partial<serviceEtablissmentUpdate.IRoot>) => ({
          ...prevState,
          payload: {
            ...prevState.payload,
            [field]: value,
          },
        }),
      );
    };

  return (
    <div className="TabContent">
      <div className="FicheTab FicheTabEtablissements fl_col gp16">
        <div className="Section-data fl_col gp10">
          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.generalInformation}
          >
            <GridItems grid={2}>
              <InputField
                isRequired={true}
                value={props.etablissementInfos?.commercial_Name}
                type="text"
                name= {languageData.global.Nomcommercial}
                label={languageData.global.Form.commercialNameLabel}
                placeholder={props.etablissementInfos?.commercial_Name}
                onChange={() => alert('x')}
                style="form"
                isDisabled={true}
              />

              <InputField
                isRequired={true}
                value={props.etablissementSheet?.share_capital}
                type="text"
                name={languageData.global.Form.shareCapitalLabel}
                label={languageData.global.Form.shareCapitalLabel}
                placeholder={props.etablissementSheet?.share_capital}
                onChange={() => alert('x')}
                style="form"
                isDisabled={true}
              />

              <InputField
                isRequired={false}
                value={
                  props.formDateState.payload?.email ||
                  props.etablissementSheet?.email
                }
                type="email"
                name={props.etablissementSheet?.email}
                label={languageData.global.Form.emailLabel}
                placeholder={props.etablissementSheet?.email}
                onChange={handleChange('email')}
                style="form"
                isDisabled={!props.claimEtablissementModification}
              />

              <SelectInput
                label={languageData.global.landline_optional}
                value={
                  props.formDateState?.payload?.fixed_line_telephone_number ||
                  props.etablissementInfos?.fixed_line_telephone_number
                }
                name={props.etablissementInfos?.fixed_line_telephone_number}
                target="reflags"
                isDisabled={props.claimEtablissementModification ? false : true}
                onChange={handleChange('fixed_line_telephone_number')}
                icons={[]}
                reference={[
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                ]}
                options={[
                  'Option01',
                  'Option02',
                  'Option03',
                  'Option0001',
                  'OptionGG',
                  'OptiDGDGGon03',
                  'Option01',
                  'OptioEn02',
                  'OptioEEEGn03',
                ]}
                //isRequired
              />

              <SelectInput
                label={languageData.global.Form.mobileLabel}
                value={
                  props.formDateState?.payload?.mobile_telephone_number ||
                  props.etablissementInfos?.mobile_telephone_number
                }
                target="reflags"
                name={props.etablissementInfos?.mobile_telephone_number}
                //isDisabled={props.claimEtablissementModification ? false : true}
                onChange={handleChange('mobile_telephone_number')}
                icons={[]}
                reference={[
                  '01',
                  '02',
                  '03',
                  '04',
                  '05',
                  '06',
                  '07',
                  '08',
                  '09',
                ]}
                options={[
                  'Option01',
                  'Option02',
                  'Option03',
                  'Option0001',
                  'OptionGG',
                  'OptiDGDGGon03',
                  'Option01',
                  'OptioEn02',
                  'OptioEEEGn03',
                ]}
                isRequired
              />
            </GridItems>
          </CardContainer>

          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.Adresse_physique}
          >
            <GridItems grid={2}>
              <InputField
                isRequired={false}
                value={
                  props.formDateState?.payload?.address ||
                  props.etablissementSheet?.address
                }
                type="text"
                name={languageData.global.addressLabel}
                label={languageData.global.adresse}
                onChange={handleChange('address')}
                placeholder={props.etablissementSheet?.address}
                style="form"
                isDisabled={false}
              />
              <InputField
                isRequired={true}
                value={
                  props.formDateState?.payload?.city ||
                  props.etablissementSheet?.city
                }
                name="city"
                label={languageData.global.state}
                type="text"
                placeholder={props.etablissementInfos?.city}
                style="form"
                // icons={flags}
                isDisabled={false}
                onChange={handleChange('city')}
                // haserror={
                //   props.validationRequested
                //     ? getError({
                //         category: 'generalInformation',
                //         fieldName: 'city',
                //         isRequired: true,
                //         fieldValue: props.formData.generalInformation?.city,
                //       })
                //     : false
                // }
                // errorMsg={
                //   props.validationRequested
                //     ? getErrorMessage({
                //         category: 'generalInformation',
                //         fieldName: 'city',
                //         isRequired: true,
                //         fieldValue: props.formData.generalInformation?.city,
                //       })
                //     : ''
                // }
              />

              <InputField
                isRequired={true}
                value={
                  props.formDateState?.payload?.postal_code ||
                  props.etablissementInfos?.postal_code
                }
                type="number"
                name="postal_code"
                label={languageData.global.Form.postalCodeLabel}
                placeholder={props.etablissementSheet?.postal_code}
                style="form"
                isDisabled={false}
                onChange={handleChange('postal_code')}
              />
            </GridItems>
          </CardContainer>

          <CardContainer
            type="form"
            ClassName="fl_row jc_fe"
            collapse
            label={languageData.global.fiscal_info}
          >
            <GridItems grid={3}>
              <InputField
                isRequired={true}
                value={
                  props.formDateState?.payload?.sireT_Code ||
                  props.etablissementInfos?.sireT_Code
                }
                type="number"
                name="sireT_Code"
                label={languageData.global.siret}
                placeholder={props.etablissementSheet?.sireT_Code}
                style="form"
                isDisabled={false}
                onChange={handleChange('sireT_Code')}
              />
              <InputField
                isRequired={true}
                value={
                  props.formDateState?.payload?.vaT_Intracommunity ||
                  props.etablissementSheet?.vaT_Intracommunity
                }
                type="text"
                name="vaT_Intracommunity"
                label={languageData.global.vat_intra}
                placeholder={props.etablissementSheet?.vaT_Intracommunity}
                style="form"
                isDisabled={false}
                onChange={handleChange('vaT_Intracommunity')}
              />
              <InputField
                isRequired={true}
                type="text"
                name={languageData.global.naf_number}
                label={languageData.global.naf_number}
                placeholder={props.etablissementSheet?.naF_codes?.designation}
                style="form"
                isDisabled={true}
              />
            </GridItems>
          </CardContainer>
        </div>
      </div>
    </div>
  );
}
